import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Curieux Israël" />
    <h3 className='underline-title'>Curieux Israël</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Philistie de Baal l'a écrasé <br />
        Rome d'Auguste l'a annihilé<br />
        Son fils Hérode l'a broyé<br />
        Tous ont péri et sont tombés en poussière<br />
        Ses cousins arabes le harcèlent <br />
        Israël<br />
        Cet araméen nomade sédentarisé<br />
        Ce Jacob lutteur nocturne contre Elohim<br />
        Peuple miniscule <br />
        s'entête prophétiquement <br />
        Et vit encore <br />
        Il vivra toujours <br />
        Comme son Electeur <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
